import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#673ab7',
        secondary: '#9c27b0',
        accent: '#3f51b5',
        error: '#f44336',
        warning: '#ff9800',
        info: '#00bcd4',
        success: '#4caf50',
      },
    },
  },
});

// export default new Vuetify({
//     theme: {
//       themes: {
//         light: {
//           primary: '#1976D2',
//           secondary: '#424242',
//           accent: '#82B1FF',
//           error: '#FF5252',
//           info: '#2196F3',
//           success: '#4CAF50',
//           warning: '#FFC107',
//         },
//       },
//     },
//   });
